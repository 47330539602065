import React, { Component } from 'react'
import NetUtils from '../public/js/NetUtils'
import { Card, Col, Row, Layout, Input } from 'antd';
import '../public/css/common.css'
const { Search } = Input;
const { Header, Footer, Sider, Content } = Layout;


export default class Ppts extends Component {
    state = {
        pptList: [],
        showPPtList: []
    }
    componentDidMount() {
        this.getCities()
    }

    getCities() {
        NetUtils.myGet("/pt-case-ppt/get-case-ppt").then(data => {
            this.setState({
                pptList: data.data,
                showPPtList: data.data
            })
        })
    }
    method = {
        searchPpt(e) {
            let showPpts = []
            if (e === "" || e === " ") {
                showPpts = this.state.pptList
            } else {
                this.state.pptList.forEach(element => {
                    if (element['pptName'].indexOf(e) >= 0 ) {
                        showPpts.push(element)
                    }
                })

            }
            this.setState({
                showPPtList: showPpts
            })
        }
    }

    render() {

        const { showPPtList } = this.state
        const pptDom = showPPtList.map((item) => {
            return <Col key={item.id} xs={24} md={8}>
                <Card style={{ marginBottom: 10 }} title={'策划名称：' + item['pptName']} extra={<div>标号：{item.id}</div>} >
                    <h3>
                        {item.pptName}
                    </h3>
                    <div className="icon">
                        <img style={{width:50}} src="https://pt-static-crostars.oss-cn-shanghai.aliyuncs.com/icon/ppt-icon.png" />
                    </div>
                    <p><a href={item['pptSrc']}><strong>点击下载PPT</strong></a></p>
                </Card>
            </Col>
        })
        return (
            <div className='common-text'>
                <Layout>
                    <Content className='common-text'>
                        <h3>公司介绍PPT以及历史策划案PPT（下载）</h3>
                        <Search placeholder="输入关键字搜索" onSearch={this.method.searchPpt.bind(this)} style={{ width: 300, marginBottom: 10 }} />
                        <Row gutter={16}>
                            {pptDom}
                        </Row >
                    </Content>
                </Layout>
            </div>
        )
    }
}
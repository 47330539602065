import './App.css';
import {
  Routes,
  Link,
  Route,
} from 'react-router-dom'
import Index from './pages/index'
import Cities from './pages/cities'
import Videos from './pages/videos'
import Article from './pages/article'
import Admin from './pages/admin'
import VideoDetail from './pages/videoDetail';
import Ppts from './pages/ppts'
import ImgPage from './pages/caseImg'

function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Index />} />
        <Route path={'/article'} element={<Article />} />
        <Route path={'/cities'} element={<Cities />} />
        <Route path={'/videos'} element={<Videos />} />
        <Route path={'/ppts'} element={<Ppts/>}/>
        <Route path={'/demo-img'} element={<ImgPage/>}/>
        <Route path={'/video-detail'} element={<VideoDetail />} />
        <Route path={'/admin'} element={<Admin />} />
      </Routes>
    </div>
  );
}

export default App;

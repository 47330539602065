import React, { Component } from 'react'
import NetUtils from '../public/js/NetUtils'
import { Card, Col, Row, Layout, Input } from 'antd';
import '../public/css/common.css'
const { Search } = Input;
const { Header, Footer, Sider, Content } = Layout;


export default class caseImg extends Component {
    state = {
        imgList: [],
        showImgList: []
    }
    componentDidMount() {
        this.getCaseImg()
    }

    getCaseImg() {
        NetUtils.myGet("/pt-case-img/get-all-case-img").then(data => {
            this.setState({
                imgList: data.data,
                showImgList: data.data.slice(0,100)
            })
        })
    }
    method = {
        searchPpt(e) {
            let showImg = []
            if (e === "" || e === " ") {
                showImg = this.state.imgList.slice(0,100)
            } else {
                this.state.imgList.forEach(element => {
                    if (element['imgName'].indexOf(e) >= 0 ) {
                        showImg.push(element)
                    }
                })

            }
            this.setState({
                showImgList: showImg.slice(0,100)
            })
        }
    }

    render() {

        const { showImgList } = this.state
        const imgDom = showImgList.map((item) => {
            return <Col key={item.id} xs={24} md={8}>
                <Card style={{ marginBottom: 10 }} title={'图片项目：' + item['imgName']} extra={<div>标号：{item.id}</div>} >
                    <h3>
                        {item.imgName}
                    </h3>
                    <div className="icon">
                        <img style={{width:'100%'}}  src={item['imgSrc']} />
                    </div>
                    <p><a href={item['imgSrc']} ><strong>点击长按图片保存</strong></a></p>
                </Card>
            </Col>
        })
        return (
            <div className='common-text'>
                <Layout>
                    <Content className='common-text'>
                        <h3>公司历史项目图片（示例图）：</h3>
                        <p><strong>点击长按图片保存,<span style={{color:'red'}}>更多图片需要进行搜索</span></strong></p>
                        <Search placeholder="输入关键字搜索" onSearch={this.method.searchPpt.bind(this)} style={{ width: 300, marginBottom: 10 }} />
                        <Row gutter={16}>
                            {imgDom}
                            <Col key='-1' xs={24} md={8}>
                                <div style={{justifyContent:'center',alignItems:'center',display:"flex",height:'100%',color:'red'}}>更多图片请搜索</div>
                            </Col>
                        </Row >
         
                    </Content>
                </Layout>
            </div>
        )
    }
}
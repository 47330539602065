import React, { Component } from 'react'
import NetUtils from '../public/js/NetUtils'
import { List, Col, Row, Layout, Input } from 'antd';
import { AudioOutlined } from '@ant-design/icons';
import '../public/css/common.css'
const { Search } = Input;
const { Header, Footer, Content } = Layout;


export default class Videos extends Component {
    state = {
        videosList: [],
        showList: []
    }
    componentDidMount() {
        this.getCities()
    }

    getCities() {
        NetUtils.myGet("/pt-video/get-list").then(data => {
            this.setState({
                videosList: data.data,
                showList: data.data
            })
        })
    }
    method = {
        searchVideos(e) {
            let showVideos = []
            if (e === "" || e === " ") {
                showVideos = this.state.videosList
            } else {
                this.state.videosList.forEach(element => {
                    if (element['videoName'].indexOf(e) >= 0) {
                        showVideos.push(element)
                    }
                })
            }
            this.setState({
                showList: showVideos
            })
        }
    }

    render() {

        const { showList } = this.state
        return (
            <div className='common-text'>
                <Layout>
                    <Content className='common-text'>
                        <h3>公开视频素材</h3>
                        <p className='red-tips'>此处视频搜索仅支持项目名称，日期搜索，支持模糊搜索例如搜索“2022”会显示名称中包含2022所有的项目视频。</p>

                        <Search placeholder="输入关键字搜索" onSearch={this.method.searchVideos.bind(this)} style={{ width: 300, marginBottom: 10 }} />
                        <List
                            itemLayout="horizontal"
                            dataSource={showList}
                            renderItem={(item) => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={<a href={"/video-detail?videoSrc=" + item.videoSrc}><span className='red-tips'>{item.videoName}</span> <span>【千机科技】</span></a>}
                                        description={"视频地址："+item.videoSrc}
                                    />
                                </List.Item>
                            )} />
                    </Content>
                </Layout>
            </div>)
    }
}
import React, { useEffect, useState } from 'react'
import NetUtils from '../public/js/NetUtils'
import { Col, Row, Layout } from 'antd';
import '../public/css/common.css'
import { useSearchParams } from 'react-router-dom';
const { Content } = Layout;


function Article() {
    const [params] = useSearchParams()
    let [article, setArtice] = useState({
        article: {}
    })


    const getArticle = (id) => {
        NetUtils.myGet("/pt-articles/get-article", { id: id }).then(data => {
            setArtice(data.data)
        })
    }

    useEffect(() => {
        getArticle(params.get("aid"))
    }, [])

    return (
        <div className='common-text'>
            <Layout>
                <Content className='common-text'>
                    <div className='article'>
                        <h3 className='text-title-center'>{article.title}</h3>
                        <Row gutter={16}>
                            <div dangerouslySetInnerHTML={{ __html: article.content }} />
                        </Row >
                    </div>

                </Content>
            </Layout>
        </div>
    )
}


export default Article
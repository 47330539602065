
import { SHOWVIEW } from "./showView"

const showViewState={
    showView:"admin-welcome"
}
function reducer(state = showViewState, action) {
    switch (action.type) {
        case "welcome":
            return { ...state, showView: "admin-welcome" }
        case "article":
            return { ...state, showView: "admin-article" }
        case "icons":
            return { ...state, showView: "admin-icon" }
        default:
            return state
    }

}
export default reducer
import React, { useEffect, useState } from 'react'
import NetUtils from '../public/js/NetUtils'
import { Col, Row, Layout } from 'antd';
import '../public/css/common.css'
import { useSearchParams } from 'react-router-dom';
import { changeAdminViewAction } from '../store/actionCreators';
import store from '../store'
const { Content } = Layout;


function Admin() {
    const [params] = useSearchParams()
    let [article, setArtice] = useState({
        article: {}
    })
    
    useEffect(()=>{
        store.subscribe(() => {
            const state = store.getState()
          })
    },[])

    let adminType = [{ name: "欢迎页", stateType: "welcome" }, { name: "文章管理", stateType: "article" }, { name: "图标管理", stateType: "icon" }, { name: "欢迎页", stateType: "2" },]
    return (
        <div className='common-text'>
            {adminType.map(item => {
                return <div key={item['stateType']} onClick={choiceAdmin.bind(this,item['stateType'])}>
                    <div style={{padding:10}}>{item["name"]}</div>
                </div>
            }
            )}
            <div>
                {store.getState().showView}
            </div>
        </div>
    )
}

function choiceAdmin(type) {
    console.log(type)
    store.dispatch(changeAdminViewAction(type))
}


export default Admin
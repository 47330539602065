// const BASE_URL = "http://192.168.31.129:8022"
const BASE_URL = "https://pt-api.crostars.cn"
export default class NetUtils{
    static async myGet(url, data={}) {
        let getStr = "?"
        for (let i in data) {
            getStr = getStr+getStr === "?" ? getStr : (getStr + "&") + String(i) + "=" + String(data[i])
        }

        return new Promise((resolve,reject)=>{
            fetch(BASE_URL + url + (getStr === "?" ? "" : getStr)).then((res) => {
                resolve(res.json())
            }).then((res)=>{
                reject(res)
            })
        })
    }
}

import React, { Component } from 'react'
import NetUtils from '../public/js/NetUtils'
import { Card, Col, Row, Layout, Input } from 'antd';
import { AudioOutlined } from '@ant-design/icons';
import '../public/css/common.css'
const { Search } = Input;
const { Header, Footer, Sider, Content } = Layout;


export default class Cities extends Component {
    state = {
        cityList: [],
        showCityList: []
    }
    componentDidMount() {
        this.getCities()
    }

    getCities() {
        NetUtils.myGet("/pt-crostars-city/get-city-list").then(data => {
            this.setState({
                cityList: data.data,
                showCityList: data.data
            })
        })
    }
    method = {
        searchCity(e) {
            let showCities = []
            if (e === "" || e === " ") {
                showCities = this.state.cityList
            } else {
                this.state.cityList.forEach(element => {
                    if (element['city'].indexOf(e) >= 0 || element['place'].indexOf(e) >= 0) {
                        showCities.push(element)
                    }
                })

            }
            console.log(showCities)
            this.setState({
                showCityList: showCities
            })
        }
    }

    render() {

        const { showCityList } = this.state
        const cities = showCityList.map((item) => {
            return <Col key={item.id} xs={24} md={8}>
                <Card style={{ marginBottom: 10 }} title={'城市：' + item['city']} extra={<div>{item.level}</div>} >
                    <h3>
                        {item.place}
                    </h3>
                    <div className="icon">
                        <img src={item.icon} />
                    </div>
                    <p>{item.cityInfo}
                    </p>
                    <p>日出时间：{item.sunRaise}</p>
                    <p>日落时间：{item.sunSet}</p>

                </Card>
            </Col>
        })
        return (
            <div className='common-text'>
                <Layout>
                    <Content className='common-text'>
                        <h3>城市地标</h3>
                        <Search placeholder="输入关键字搜索" onSearch={this.method.searchCity.bind(this)} style={{ width: 300, marginBottom: 10 }} />
                        <Row gutter={16}>
                            {cities}
                        </Row >
                    </Content>
                </Layout>

            </div>

        )
    }
}
import '../public/css/common.css'
import { useSearchParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

let status=0
function handleDownload(src) {
  
    if(status==0){
        status=1
        let link = src
        let fileName = 'video.mp4'
        let x = new XMLHttpRequest()
        x.open('GET', link, true)
        x.responseType = 'blob'
        x.onload = (e) => {
          let url = window.URL.createObjectURL(x.response)
          let a = document.createElement('a')
          a.href = url
          a.download = fileName
          a.click()
        }
        x.send()
        status=0
    }else{
        alert("正在下载请稍后")
    }
  }

function VideoDetail() {
    const [params] = useSearchParams()
    let [videoSrc, setVideoSrc] = useState("")
    useEffect(() => {
        setVideoSrc(params.get("videoSrc"))
    }, [])

    return <div>
        <video src={videoSrc} controls className="videoShowItem"></video>
        <div style={{ "padding": 20 }}>
            <span>视频网址(长按复制)：</span>
            <div>{videoSrc}</div>
            <button onClick={handleDownload.bind(this,videoSrc)}>视频下载：点击下载</button>
        </div>
    </div>
}
export default VideoDetail
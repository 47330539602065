import React, { Component } from 'react'
import NetUtils from '../public/js/NetUtils'
import '../public/css/index.css';

export default class Index extends Component {
    state = {
        icons: []
    }
    componentDidMount() {
        this.getIcons()
    }

    getIcons() {
        NetUtils.myGet("/pt-icons/get-icons").then(data => {
            this.setState({
                icons: data.data
            })
        })
    }
    render() {
        const icons = this.state.icons.map((item) => {
            return <div key={item.id} className="item">
                <a target="_blank" href={item.url}>
                    <div className="icon">
                        <img src={item.icon} />
                    </div>
                    <div>{item.name}
                    </div>
                </a>
            </div>
        })
        return (
            <div className='bg'>
                <h1>千机科技应用</h1>
                <div className='items'>
                    {icons}
                </div>
            </div>
        )
    }
}